import {MainView, MainViewProps, MainViewState} from "../App";
import React from "react";
import {NavigateFunction} from "react-router-dom";

export interface MainPageProps extends MainViewProps {
    games : {
        id: string,
        name: string
    }[]
}

export interface MainPageState extends MainViewState {}

export class MainPage extends MainView<MainPageProps, MainPageState> {
    protected initialState(): MainViewState {
        return {};
    }

    protected renderContentPage() {
        return (
            <div className="App">
                <div className="CardContainer">
                    {this.props.games.map((game) => {
                        return (
                            <GameLaunchIcon key={game.id}
                                            gameId={game.id}
                                            gameName={game.name}
                                            navigate={this.props.navigate} />
                        );
                    })}
                </div>
            </div>
        );
    }
}

interface GameLaunchIconProps {
    gameId: string,
    gameName: string,
    navigate: NavigateFunction;
}

interface GameLaunchIconState {}

class GameLaunchIcon extends React.Component<GameLaunchIconProps, GameLaunchIconState> {
    protected constructor(props: GameLaunchIconProps) {
        super(props);
        this.state = {};
    }

    startGame(game: string) {
        this.props.navigate(`/game/${game}`);
    }

    render() {
        return (
            <div className="Card" onClick={this.startGame.bind(this, this.props.gameId)}>
                <div className="CardContent">
                    <div>
                        <img src={require(`../static/game_${this.props.gameId}.png`)} alt={this.props.gameName} />
                    </div>
                    <div className="centerText">{this.props.gameName}</div>
                </div>
            </div>
        );
    }
}
