import de_words from "./static/de_words.txt";

export class WordHandler {
    private wordLengthRanges?: {
        min: number,
        max: number,
    };
    private words: string[];

    private static instance: WordHandler;

    public static getInstance(): WordHandler {
        if (!WordHandler.instance) {
            WordHandler.instance = new WordHandler();
        }

        return WordHandler.instance;
    }

    private constructor() {
        this.words = [];
    }

    private async loadWords(minWordLength: number, maxWordLength: number) : Promise<string[]> {
        return fetch(de_words)
            .catch((error) => {
                console.log(error);
                return undefined;
            })
            .then(response => {
                return (response === undefined) ? "" : response.text()
            })
            .then(content => {
                return content.split(/\r?\n/)
                    .filter(word => {
                        return (word.length >= minWordLength && word.length <= maxWordLength);
                    });
            })
            .then(words => {
                return words.map(word => word.replace("ß", "ss"));
            });
    }

    private async getWordsForWordLengthRange(minWordLength: number, maxWordLength: number) : Promise<string[]> {
        if (this.wordLengthRanges && this.wordLengthRanges.min === minWordLength && this.wordLengthRanges.max === maxWordLength) {
            return this.words
        }
        return this.loadWords(minWordLength, maxWordLength)
            .then((words) => {
                this.words = words;
                this.wordLengthRanges = {
                    min: minWordLength,
                    max: maxWordLength,
                };
                return words;
            })
    }

    async getRandomWords(numWords: number, minWordLength: number, maxWordLength: number) : Promise<string[]> {
        const words = await this.getWordsForWordLengthRange(minWordLength, maxWordLength);
        if (words.length <= numWords) {
            return words;
        }
        const selectedWords = new Set<string>();
        while (selectedWords.size < numWords) {
            const randomInt = Math.floor(Math.random() * words.length);
            selectedWords.add(words[randomInt]);
        }
        return Array.from(selectedWords.values());
    }
}