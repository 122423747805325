import React from "react";

export interface ConfirmationDialogProps {
    question: string;
    answer_yes: () => void;
    answer_no: () => void;
}

export class ConfirmationDialog extends React.Component<ConfirmationDialogProps> {
    render() {
        return (
            <div className="App">
                {this.props.question}
                <div className="ActionBar">
                <span
                    onClick={this.props.answer_yes.bind(this)}
                    title="Confirm with yes"
                    className="ActionButton">Yes</span>
                <span
                    onClick={this.props.answer_no.bind(this)}
                    title="Confirm with no"
                    className="ActionButton">No</span>
                </div>
            </div>
    );
    }
}

export function cleanTextInput(input: string): string {
    return input.trim();
}

export function submitOnEnter(event: React.KeyboardEvent<HTMLInputElement>,
                              submit?: () => void | undefined) {
    if (!submit) {
        return;
    }
    if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        submit();
    }
}