import React from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes, useNavigate} from 'react-router-dom';
import {AppUserState} from "./Data";
import registerServiceWorker, {unregister} from "./serviceWorker";
import {MainPage} from "./ui/MainPage";
import { createRoot } from 'react-dom/client';
import {RememberWordsGame} from "./ui/RememberWordsGame";
import {WordHandler} from "./WordHandler";
import {MemoryGame} from "./ui/MemoryGame";

const games = [
    {
        id: "remember_words",
        name: "Remember Words",
    },{
        id: "memory",
        name: "Word Memory",
    },
]

function Overview(props: { appState: AppUserState }) {
    const navigate = useNavigate();
    return <MainPage
        appState={props.appState}
        navigate={navigate}
        games={games}/>
}

function RememberWords(props: { appState: AppUserState }) {
    const navigate = useNavigate();
    return <RememberWordsGame
        appState={props.appState}
        navigate={navigate}/>
}

function Memory(props: { appState: AppUserState }) {
    const navigate = useNavigate();
    return <MemoryGame
        appState={props.appState}
        navigate={navigate}/>
}

unregister();

registerServiceWorker();

const appState = {
    wordHandler: WordHandler.getInstance(),
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={<Overview appState={appState}/>}/>
                <Route
                    path="/game/remember_words"
                    element={<RememberWords appState={appState}/>}/>
                <Route
                    path="/game/memory"
                    element={<Memory appState={appState}/>}/>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();