import {MainView, MainViewProps, MainViewState} from "../App";
import React from "react";
import MultiRangeSlider, {ChangeResult} from "multi-range-slider-react";
import {cleanTextInput, submitOnEnter} from "../Common";
import Moment from 'moment';

enum RememberWordsGamePhase {
    NEW_ROUND,
    SHOW_WORDS,
    INPUT_WORDS,
    CORRECT_WORDS,
}
export interface RememberWordsGameProps extends MainViewProps {}

export interface RememberWordsGameState extends MainViewState {
    gameConfig: GameConfig,
    words: string[],
    rememberedWords: string[],
    startTimeMs: number,
    endTimeMs: number,
    gameStats: Stats,
    phase: RememberWordsGamePhase,
}

export class RememberWordsGame extends MainView<RememberWordsGameProps, RememberWordsGameState> {
    protected initialState(): RememberWordsGameState {
        const gameConfig = GameState.getInstance().getGameConfig();
        return {
            gameConfig: gameConfig,
            words: [],
            rememberedWords: [],
            startTimeMs: 0,
            endTimeMs: 0,
            phase: RememberWordsGamePhase.NEW_ROUND,
            gameStats: GameState.getInstance().getStats(gameConfig.numWords),
        };
    }

    private validateNumberWords() {
        let numWords = this.state.gameConfig.numWords;
        if (numWords < 1) {
            numWords = 1;
        } else if (numWords > 50) {
            numWords = 50;
        }
        if (numWords !== this.state.gameConfig.numWords) {
            this.setState((initialState) => {
                return {
                    ...initialState,
                    gameConfig: {
                        ...initialState.gameConfig,
                        numWords: numWords,
                    }
                };
            });
        }
    }

    private updateNumberWords(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState((initialState) => {
            return {
                ...initialState,
                gameConfig: {
                    ...initialState.gameConfig,
                    numWords: +event.target.value,
                }
            };
        });
    }

    private updateWordLength(event: ChangeResult) {
        this.setState((initialState) => {
            return {
                ...initialState,
                gameConfig: {
                    ...initialState.gameConfig,
                    wordLength: {
                        min: event.minValue,
                        max: event.maxValue,
                    },
                }
            };
        });
    }

    private renderGameStatsRecord(title: string,
                                  gameStatsRecord: StatsRecord) {
        return (
            <div className="Group" key={title}>
                {title}
                <div>Strikes: {gameStatsRecord.strikes}</div>
                <div>Time: {Moment(new Date(gameStatsRecord.avgTime)).format("mm:ss:SSS")}</div>
            </div>
        );
    }

    private renderGameStats() {
        return (
            <div className="centerText">
                {this.renderGameStatsRecord("Current", this.state.gameStats.current)}
                {this.renderGameStatsRecord("Best", this.state.gameStats.best)}
            </div>
        );
    }

    private renderGameConfig() : JSX.Element {
        return (
            <div className="InputContainer">
                {this.renderGameStats()}
                <div className="BlockContent">
                    <label>Number words</label>
                    <span className="InputFieldContainer">
                        <input className="fullWidth"
                               type="number"
                               value={this.state.gameConfig.numWords}
                               placeholder="Number words"
                               onChange={this.updateNumberWords.bind(this)}
                               onBlur={this.validateNumberWords.bind(this)}/>
                    </span>
                </div>
                <div className="BlockContent">
                    <label>Word length</label>
                    <span className="InputFieldContainer">
                        <MultiRangeSlider
                            min={GameState.getDefaultGameConfig().wordLength.min}
                            max={25}
                            step={1}
                            minValue={this.state.gameConfig.wordLength.min}
                            maxValue={this.state.gameConfig.wordLength.max}
                            onChange={this.updateWordLength.bind(this)}/>
                    </span>
                </div>
                <div className="rightFlow">
                    <button
                        onClick={this.startGame.bind(this)}
                        autoFocus={true}
                        className="ActionButton">Start Game</button>
                </div>
            </div>
        );
    }

    private startGame() {
        GameState.getInstance().setGameConfig(this.state.gameConfig);
        this.props.appState.wordHandler.getRandomWords(this.state.gameConfig.numWords, this.state.gameConfig.wordLength.min, this.state.gameConfig.wordLength.max)
            .then((words) => {
                this.setState((initialState) => {
                    return {
                        ...initialState,
                        words: words,
                        rememberedWords: words.map(() => ""),
                        phase: RememberWordsGamePhase.SHOW_WORDS,
                        startTimeMs: new Date().getTime(),
                    };
                });
            });
    }

    private startInputtingWords() {
        const endTimeMs = new Date().getTime();
        this.setState((initialState) => {
            return {
                ...initialState,
                phase: RememberWordsGamePhase.INPUT_WORDS,
                endTimeMs: endTimeMs,
            };
        });
    }

    private renderShowWords() : JSX.Element {
        return (
            <div className="InputContainer">
                {this.state.words.map((word, index) => {
                    return (
                        <div key={index}>
                            <span className="InputFieldContainer">{word}</span>
                        </div>
                    );
                })}
                <div className="rightFlow">
                    <button
                        onClick={this.startInputtingWords.bind(this)}
                        autoFocus={true}
                        className="ActionButton">Remember Words</button>
                </div>
            </div>
        );
    }

    private updateRememberedWord(index: number, event: React.ChangeEvent<HTMLInputElement>) {
        const rememberedWords = this.state.rememberedWords;
        rememberedWords[index] = event.target.value;
        this.setState((initialState) => {
            return {
                ...initialState,
                gameConfig: {
                    ...initialState.gameConfig,
                    rememberedWords: rememberedWords,
                }
            };
        });
    }

    private evaluateWords() {
        const stats = GameState.getInstance().updateStats(this.getGameTime(), this.getCorrectWords().length, this.state.rememberedWords.length);
        this.setState((initialState) => {
            return {
                ...initialState,
                phase: RememberWordsGamePhase.CORRECT_WORDS,
                gameStats: stats,
            };
        });
    }

    private renderRememberWords() : JSX.Element {
        return (
            <div className="InputContainer">
                {this.state.words.map((word, index) => {
                    return (
                        <div key={index}>
                            <span className="InputFieldContainer">
                                <input className="fullWidth"
                                       type="text"
                                       value={this.state.rememberedWords[index]}
                                       placeholder="Number words"
                                       onKeyDown={(e) => submitOnEnter(e, this.evaluateWords.bind(this))}
                                       autoFocus={index === 0}
                                       onChange={this.updateRememberedWord.bind(this, index)}/>
                            </span>
                        </div>
                    );
                })}
                <div className="rightFlow">
                    <button
                        onClick={this.evaluateWords.bind(this)}
                        className="ActionButton">Evaluate</button>
                </div>
            </div>
        );
    }

    private newRound() {
        this.setState((initialState) => {
            return {
                ...initialState,
                words: [],
                rememberedWords: [],
                startTimeMs: 0,
                endTimeMs: 0,
                phase: RememberWordsGamePhase.NEW_ROUND
            };
        })
    }

    private renderIncorrectWords(incorrectWords: string[], expectedWords: string[]) {
        if (incorrectWords.length === 0) {
            return;
        }
        return (
            <div className="Block">
                <div className="Answer">
                    <div className="incorrectAnswer">
                        {incorrectWords.map((incorrectWord, index) => {
                            return (
                                <div key={index}>{incorrectWord}</div>
                            );
                        })}
                    </div>
                </div>
                <div className="Answer">
                    <div className="correctAnswer">
                        {expectedWords.map((expectedWord, index) => {
                            return (
                                <div key={index}>{expectedWord}</div>
                            );
                        })}
                    </div>
                </div>
            </div>
        )
    }

    private getGameTime() {
        return new Date(this.state.endTimeMs - this.state.startTimeMs);
    }

    private getCorrectWords() {
        const cleanedRememberedWords = this.getCleanedRememberedWords();
        return cleanedRememberedWords.filter((word) => {
            return this.state.words.includes(word);
        });
    }

    private getCleanedRememberedWords() {
        return this.state.rememberedWords.map(word => cleanTextInput(word));
    }

    private getIncorrectWords() {
        const cleanedRememberedWords = this.getCleanedRememberedWords();
        return cleanedRememberedWords.filter((word) => {
            return !this.state.words.includes(word);
        });
    }

    private getExpectedWords() {
        const cleanedRememberedWords = this.getCleanedRememberedWords();
        return this.state.words.filter((word) => {
            return !cleanedRememberedWords.includes(word);
        })
    }

    private renderWordCorrections() : JSX.Element {
        const correctWords = this.getCorrectWords();
        const incorrectWords =  this.getIncorrectWords();
        const expectedWords = this.getExpectedWords();

        return (
            <div className="InputContainer">
                {this.renderGameStats()}
                <div className="BlockContent">
                    <label>Correct words</label>
                    {`${correctWords.length} / ${this.state.words.length}`}
                </div>
                <div className="BlockContent">
                    <label>Time</label>
                    {Moment(this.getGameTime()).format("mm:ss:SSS")}
                </div>
                <div className="BlockContent">
                    {correctWords.map((correctWord, index) => {
                        return (
                            <div key={index}
                                 className="Block">
                                <div className="Answer"><div className="correctAnswer">{correctWord}</div></div>
                            </div>
                        );
                    })}
                    {this.renderIncorrectWords(incorrectWords, expectedWords)}
                </div>
                <div className="rightFlow">
                    <button
                        onClick={this.newRound.bind(this)}
                        autoFocus={true}
                        className="ActionButton">New Round</button>
                </div>
            </div>
        );
    }

    protected renderContentPage() : JSX.Element {
        switch (this.state.phase) {
            case RememberWordsGamePhase.NEW_ROUND:
                return this.renderGameConfig();
            case RememberWordsGamePhase.SHOW_WORDS:
                return this.renderShowWords();
            case RememberWordsGamePhase.INPUT_WORDS:
                return this.renderRememberWords();
            case RememberWordsGamePhase.CORRECT_WORDS:
                return this.renderWordCorrections();
        }
    }
}

type StatsRecord = {
    strikes: number;
    avgTime: number;
};

type Stats = {
  current: StatsRecord;
  best: StatsRecord;
};

type GameConfig = {
    wordLength: {
        min: number;
        max: number;
    },
    numWords: number;
}

class GameState {
    private static instance: GameState;

    public static getInstance(): GameState {
        if (!GameState.instance) {
            GameState.instance = new GameState();
        }

        return GameState.instance;
    }

    private static getStatsPrefixForNumWords(numWords: number) : string {
        return `remember_words_stats_wc_${numWords}`;
    }

    public updateStats(gameTime: Date, correctWords: number, totalWords: number) {
        let stats = this.getStats(totalWords);
        if (correctWords === totalWords) {
            stats.current.avgTime = ((stats.current.avgTime * stats.current.strikes) + gameTime.getTime()) / (stats.current.strikes + 1);
            stats.current.strikes++;
        } else {
            stats.current.strikes = 0;
            stats.current.avgTime = gameTime.getTime();
        }
        if (stats.current.strikes > stats.best.strikes ||
            (stats.current.strikes === stats.best.strikes && stats.current.avgTime < stats.best.avgTime)) {
            stats.best = stats.current;
        }
        const statsKey = GameState.getStatsPrefixForNumWords(totalWords);
        localStorage.setItem(statsKey, JSON.stringify(stats));
        return stats;
    }

    public getStats(totalWords: number) : Stats {
        const statsKey = GameState.getStatsPrefixForNumWords(totalWords);
        const data = localStorage.getItem(statsKey);
        let stats: Stats;
        if (!data) {
            stats = {
                current: this.getDefaultStatsRecord(),
                best: this.getDefaultStatsRecord(),
            };
        } else {
            stats = JSON.parse(data);
        }
        return stats;
    }

    private getDefaultStatsRecord() {
        return {
            strikes: 0,
            avgTime: 60 * 60 * 1000,  // 1 hour
        };
    }

    private static getConfigPrefix() : string {
        return "remember_words_config";
    }

    public static getDefaultGameConfig() {
        return {
            numWords: 3,
            wordLength: {
                min: 5,
                max: 10
            },
        };
    }

    public getGameConfig() : GameConfig {
        const configKey = GameState.getConfigPrefix();
        const data = localStorage.getItem(configKey);
        let config: GameConfig;
        if (!data) {
            config = GameState.getDefaultGameConfig();
        } else {
            config = JSON.parse(data);
        }
        return config;
    }

    public setGameConfig(gameConfig: GameConfig) {
        const configKey = GameState.getConfigPrefix();
        localStorage.setItem(configKey, JSON.stringify(gameConfig));
    }
}