import {MainView, MainViewProps, MainViewState} from "../App";
import React from "react";

enum MemoryGamePhase {
    NEW_ROUND,
    PLAY_MEMORY,
}

type Card = {
    word: string,
    open: boolean,
    discovered: boolean,
};

export interface MemoryGameProps extends MainViewProps {}

export interface MemoryGameState extends MainViewState {
    gameConfig: GameConfig,
    boardState: Card[][],
    firstCard?: {row: number, column: number},
    secondCard?: {row: number, column: number},
    mistakes: number,
    phase: MemoryGamePhase,
}

export class MemoryGame extends MainView<MemoryGameProps, MemoryGameState> {
    protected initialState(): MemoryGameState {
        const gameConfig = GameState.getInstance().getGameConfig();
        return {
            gameConfig: gameConfig,
            boardState: [],
            phase: MemoryGamePhase.NEW_ROUND,
            mistakes: 0,
        };
    }

    private validateNumberRows() {
        let rows = this.state.gameConfig.rows;
        if (rows < 1) {
            rows = 1;
        } else if (rows > 10) {
            rows = 10;
        }
        if (rows !== this.state.gameConfig.rows) {
            this.setState((initialState) => {
                return {
                    ...initialState,
                    gameConfig: {
                        ...initialState.gameConfig,
                        rows: rows,
                    }
                };
            });
        }
    }

    private updateNumberRows(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState((initialState) => {
            return {
                ...initialState,
                gameConfig: {
                    ...initialState.gameConfig,
                    rows: +event.target.value,
                }
            };
        });
    }

    private renderGameConfig() : JSX.Element {
        return (
            <div className="InputContainer">
                <div className="BlockContent">
                    <label>Number words</label>
                    <span className="InputFieldContainer">
                        <input className="fullWidth"
                               type="number"
                               value={this.state.gameConfig.rows}
                               placeholder="Number rows"
                               onChange={this.updateNumberRows.bind(this)}
                               onBlur={this.validateNumberRows.bind(this)}/>
                    </span>
                </div>
                <div className="rightFlow">
                    <button
                        onClick={this.startGame.bind(this)}
                        autoFocus={true}
                        className="ActionButton">Start Game</button>
                </div>
            </div>
        );
    }

    private initializeBoard(words: string[]): Card[][] {
        const shuffledWords = [ ...words, ...words].sort(() => 0.5 - Math.random());
        const rows = words.length / 2;
        const cards: Card[][] = [];
        for (let i = 0; i < rows; i++) {
            const row: Card[] = [];
            for (let j = 0; j < 4; j++) {
                row.push({
                    word: shuffledWords[(i * 4) + j],
                    open: false,
                    discovered: false,
                })
            }
            cards.push(row);
        }
        return cards;
    }

    private startGame() {
        GameState.getInstance().setGameConfig(this.state.gameConfig);
        this.props.appState.wordHandler.getRandomWords(this.state.gameConfig.rows * 2, 5, 15)
            .then((words) => {
                this.setState((initialState) => {
                    return {
                        ...initialState,
                        boardState: this.initializeBoard(words),
                        firstCard: undefined,
                        secondCard: undefined,
                        phase: MemoryGamePhase.PLAY_MEMORY,
                    };
                });
            });
    }

    private openCard(row: number, column: number) {
        if (this.state.boardState[row][column].open) {
            return;
        }

        const updates = this.updatedBoardState(row, column, this.state);
        this.setState(() => {
            return updates;
        });
    }

    private updatedBoardState(row: number, column: number, initialBoardState: MemoryGameState) : {} {
        const updatedState = JSON.parse(JSON.stringify(initialBoardState));
        updatedState.boardState[row][column].open = true;

        if (initialBoardState.firstCard === undefined) {
            updatedState.firstCard = {
                row: row,
                column: column,
            };
            updatedState.secondCard = undefined;
            return updatedState;
        }

        const firstCard = initialBoardState.boardState[initialBoardState.firstCard.row][initialBoardState.firstCard.column];

        if (initialBoardState.secondCard === undefined) {
            updatedState.secondCard =  {
                row: row,
                column: column,
            };
            const secondCard = initialBoardState.boardState[row][column];

            if (firstCard.word !== secondCard.word) {
                if (firstCard.discovered || secondCard.discovered) {
                    updatedState.mistakes = updatedState.mistakes + 1;
                }
            }
            return updatedState;
        }

        const secondCard = initialBoardState.boardState[initialBoardState.secondCard.row][initialBoardState.secondCard.column];
        updatedState.firstCard = undefined;
        updatedState.secondCard = undefined;
        updatedState.boardState[initialBoardState.firstCard.row][initialBoardState.firstCard.column].discovered = true;
        updatedState.boardState[initialBoardState.secondCard.row][initialBoardState.secondCard.column].discovered = true;

        if (firstCard.word !== secondCard.word) {
            updatedState.boardState[initialBoardState.firstCard.row][initialBoardState.firstCard.column].open = false;
            updatedState.boardState[initialBoardState.secondCard.row][initialBoardState.secondCard.column].open = false;
        }

        return this.updatedBoardState(row, column, updatedState);
    }

    private renderCard(card: Card, row: number, column: number) : JSX.Element {
        if (card.open) {
            return (
                <div className="Card">
                    <div className="centerText" style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>{card.word}</div>
                </div>
            );
        } else {
            return (
                <div className="Card LargeActionText"
                     onClick={this.openCard.bind(this, row, column)}>&nbsp;</div>
            );
        }
    }

    private renderGameStats() {
        return (
            <div className="centerText">
                <div className="Group">
                    <div>Mistakes: {this.state.mistakes}</div>
                </div>
            </div>
        );
    }

    private renderMemoryBoard() : JSX.Element {
        return (
            <div className="InputContainer">
                {this.renderGameStats()}
                {this.state.boardState.map((rowCards, row) => {
                    return <div key={row} className="CardContainer">
                        {rowCards.map((card, column) => {
                            return (
                                <div key={row + "x" + column} className="CardContainer">
                                    {this.renderCard(card, row, column)}
                                </div>
                            );
                        })}
                        </div>
                })}
                <div className="rightFlow">
                    <button
                        onClick={this.newRound.bind(this)}
                        autoFocus={true}
                        className="ActionButton">New Game</button>
                </div>
            </div>
        );
    }

    private newRound() {
        this.setState((initialState) => {
            return {
                ...initialState,
                firstCard: undefined,
                boardState: [],
                phase: MemoryGamePhase.NEW_ROUND,
                mistakes: 0,
            };
        })
    }

    protected renderContentPage() : JSX.Element {
        switch (this.state.phase) {
            case MemoryGamePhase.NEW_ROUND:
                return this.renderGameConfig();
            case MemoryGamePhase.PLAY_MEMORY:
                return this.renderMemoryBoard();
        }
    }
}

type GameConfig = {
    rows: number;
}

class GameState {
    private static instance: GameState;

    public static getInstance(): GameState {
        if (!GameState.instance) {
            GameState.instance = new GameState();
        }

        return GameState.instance;
    }

    private static getConfigPrefix() : string {
        return "memory_config";
    }

    public static getDefaultGameConfig() {
        return {
            rows: 3,
        };
    }

    public getGameConfig() : GameConfig {
        const configKey = GameState.getConfigPrefix();
        const data = localStorage.getItem(configKey);
        let config: GameConfig;
        if (!data) {
            config = GameState.getDefaultGameConfig();
        } else {
            config = JSON.parse(data);
        }
        return config;
    }

    public setGameConfig(gameConfig: GameConfig) {
        const configKey = GameState.getConfigPrefix();
        localStorage.setItem(configKey, JSON.stringify(gameConfig));
    }
}