import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import './App.css';
import { AppUserState } from './Data';

export interface MainViewProps {
  navigate: NavigateFunction;
  appState: AppUserState;
}

export interface MainViewState {}

export abstract class MainView<Props extends MainViewProps, State extends MainViewState> extends React.Component<Props, State> {
  protected constructor(props: Props) {
    super(props);
    this.state = this.initialState();
  }

  protected abstract initialState() : State;

  protected abstract renderContentPage() : JSX.Element;

  protected showError(error: string) {
    console.log(error);
  }

  render() {
    return (
        <div className="App">
          <table>
            <tbody>
            <tr>
              <td>

              </td>
            </tr>
            <tr>
              <td>{this.renderContentPage()}</td>
            </tr>
            </tbody>
          </table>
        </div>
    );
  }
}
